import { auth4, db4, GoogleProvider4 } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useState } from 'react';
import { Registration } from './register';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';

export const Auth = ({ onShow, onClose, loading, setLoading, email: initialEmail }) => {

    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState(null);
    const [loginError, setLoginError] = useState(null);


    const [showAuthModal, setShowAuthModal] = useState(true);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);

    const handleClose = () => {
        if (onClose) {
            // onClose();
        }
    };

    if (onShow) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }

    const logIn = async () => {
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth4, email, password);
            const user = userCredential.user;
    
            // Check user's email domain
            if (!user.email.endsWith('@kamleon.com')) {
                setLoginError('Invalid email domain. Please use a @kamleon.com email.');
                setLoading(false);
                return;
            }
    
            // Check user's role
            const userDocRef = doc(db4, 'users', user.uid);
            const userDocSnapshot = await getDoc(userDocRef);

            if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                if (!(userData.kamleonPermissions.userRole === 'kamleon_admin' || userData.kamleonPermissions.userRole === 'kamleon_editor' || userData.kamleonPermissions.userRole === "kamleon_viewer" || userData.kamleonPermissions.userRole === 'kamleon_owner')) {
                    setLoginError('User not authorized. Please contact support.');
                    setLoading(false);
                    return;
                } else {
                    // Proceed with login logic...
                    handleClose();
                }
            } else {
                setLoginError('User document not found.');
            }
        } catch (error) {
            console.log(error);
            setLoginError("Something went wrong during the login process, please contact support.");
            setLoading(false);
        }
    }

    const signInWithGoogle = async () => {
        setLoading(true);
        try {
            await signInWithPopup(auth4, GoogleProvider4);
            handleClose();
        } catch (error) {
            console.log(error);
            setLoading(false);
        }  
    };

    return (
        <div>
            <Modal
                show={onShow} 
                tabIndex="-1"
                onHide={onClose}
                backdrop="static"
            >
                <Modal.Header>
                    <div className="container">
                        <div className="row justify-content-center pt-5">
                            <div className="col-12 text-center">
                                <Modal.Title className='customBoldSoft'>Log In</Modal.Title>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='px-5 pb-4 pt-2'>
                    <Form>
                        <Form.Group controlId="formBasicEmail" className='mb-4'>
                            <Form.Label className='customBoldSoft'>EMAIL</Form.Label>
                            <Form.Control value={email} type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label className='customBoldSoft'>PASSWORD</Form.Label>
                            <Form.Control type="password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        {loginError && (
                            <p className="text-danger">{loginError}</p>
                        )}
                        <div className="container mt-5">
                            <div className="row justify-content-end">
                                <Button variant="primary" onClick={logIn} style={{background: 'black', color: 'white'}}>
                                    {loading ? (
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    ) : (
                                        <h5 className='mb-0'><strong>Log In</strong></h5>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                </Modal.Footer>
            </Modal>
            {showRegistrationModal && (
                <Registration
                    onShow={showRegistrationModal}
                    onClose={() => {setShowRegistrationModal(false); setShowAuthModal(false); handleClose();}}
                />
            )}
        </div>
    );
}