import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig4 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_4,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_4,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_4,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_4,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_4,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_4,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_4,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_4,
};

// Initialize Firebase
const app4 = initializeApp(firebaseConfig4, "app4");

export const auth4 = getAuth(app4);

export const GoogleProvider4 = new GoogleAuthProvider();

export const db4 = getFirestore(app4);

export const database = getDatabase(app4);
